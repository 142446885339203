<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 22.173 22.173"
  >
    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(0.9 0.9)">
      <path
        data-name="Path 3693"
        d="M20.667,11.333A9.333,9.333,0,1,1,11.333,2,9.333,9.333,0,0,1,20.667,11.333Z"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#bdbec2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.8"
      />
      <path
        id="Path_3694"
        data-name="Path 3694"
        d="M14,14l-2.9-2.9"
        transform="translate(6 6)"
        fill="none"
        stroke="#bdbec2"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.8"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '16',
    },
    width: {
      type: String,
      default: '16',
    },
    color: {
      type: String,
      default: 'icon-color-grey',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
